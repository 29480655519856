@import "libs/fonts";
@import "libs/icons";
@import "libs/magnificPopup.css";
@import "libs/owl.carousel.min.css";
@import "libs/leaflet.css";
@import "libs/icheck.css";

.icheckbox_square-blue,
.iradio_square-blue {
    background-image: url("../img/blue.png");
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_square-blue,
    .iradio_square-blue{
        background-image: url("../img/blue@2x.png");
    }
}

 
