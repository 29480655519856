@font-face {
  font-family: 'iconpack';
  src:  url('../fonts/iconpack.eot?bhj6br');
  src:  url('../fonts/iconpack.eot?bhj6br#iefix') format('embedded-opentype'),
    url('../fonts/iconpack.ttf?bhj6br') format('truetype'),
    url('../fonts/iconpack.woff?bhj6br') format('woff'),
    url('../fonts/iconpack.svg?bhj6br#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}
i.icon{
    font-family: 'iconpack' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.icon-car:before {
      content: "\e90f";
    }
    &.icon-snowflake:before {
      content: "\e908";
    }
    &.icon-pay:before {
      content: "\e907";
    }
    &.icon-car-door:before {
      content: "\e909";
    }
    &.icon-crash:before {
      content: "\e90a";
    }
    &.icon-showroom:before {
      content: "\e90b";
    }
    &.icon-spring:before {
      content: "\e90c";
    }
    &.icon-time:before {
      content: "\e90d";
    }
    &.icon-towed-car:before {
      content: "\e90e";
    }
    &.icon-calculator:before {
      content: "\e900";
    }
    &.icon-carkey:before {
      content: "\e901";
    }
    &.icon-carservice:before {
      content: "\e902";
    }
    &.icon-car1:before {
      content: "\e903";
    }
    &.icon-racing:before {
      content: "\e904";
    }
    &.icon-arrow-right:before {
      content: "\e905";
    }
    &.icon-truck:before {
      content: "\e906";
    }
    &.icon-close:before {
      content: "\e912";
    }
    &.icon-menu:before {
      content: "\e914";
    }
}
